import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

export function Landing() {
  const year = new Date().getFullYear();
  return (
    <div className="font-body">
      <header className="py-4 px-6 absolute top-0 left-0 right-0 z-10">
        <div className="container mx-auto">
          <div className="flex items-center">
            <div className="flex-1">
              <img alt="logo" className="h-24" src="/img/jobify-logo.png" />
            </div>
            <nav className="hidden lg:flex items-center">
              <a
                className="px-6 py-3 font-bold uppercase text-primary border-b-2 border-primary"
                href="/"
              >
                {`Home`}
              </a>
              <a
                className="px-6 py-3 font-bold uppercase text-secondary hover:text-primary"
                href="#our-mission"
              >
                {`Our mission`}
              </a>
              <a
                className="px-6 py-3 font-bold uppercase text-secondary hover:text-primary"
                href="#about-us"
              >
                {`About us`}
              </a>
              <a
                className="px-6 py-3 font-bold uppercase text-secondary hover:text-primary"
                href="#services"
              >
                {`Services`}
              </a>
              <a
                className="px-6 py-3 font-bold uppercase text-secondary hover:text-primary"
                href="#why-jobify"
              >
                {`Why JOBIFY`}
              </a>
              <a
                className="px-6 py-3 font-bold uppercase text-secondary hover:text-primary"
                href="#testimonials"
              >
                {`Testimonials`}
              </a>
            </nav>
          </div>
        </div>
      </header>
      <div className="relative overflow-hidden px-6 pb-6">
        <img
          alt="wave"
          className="absolute top-0 left-2/5"
          src="/img/wave.svg"
        />
        <div className="container mx-auto relative">
          <div className="flex flex-col md:flex-row items-center pt-32 pb-16 md:pb-0">
            <div className="md:w-1/2 lg:w-1/3 mb-4 sm:mb-16 md:mb-0">
              <h2 className="text-xl font-bold text-secondary-600 uppercase mb-2">
                {`Reimagine your human capital strategy with more efficiency &
data integrity`}
              </h2>
              <h1 className="text-4xl font-bold text-secondary leading-tight mb-6 md:mb-10">
                {`JOBIFY Registration`}
              </h1>
              <a
                className="bg-primary px-6 md:px-8 py-3 md:py-4 text-lg md:text-xl text-white font-bold uppercase rounded hover:bg-secondary-400"
                href="https://dashboard.jobify.website"
              >
                <span className="text-white">{`Register`}</span>
              </a>
            </div>
            <div className="mt-16 sm:mt-0">
              <img alt="hospital" src="/img/organize_resume.svg" />
            </div>
          </div>
        </div>
      </div>

      <div className="relative" id="our-mission">
        <img
          alt="circle"
          className="hidden sm:block absolute top-0 left-0 -mx-32"
          src="/img/circle2.svg"
        />
        <div className="container mx-auto px-6 pt-6 pb-12 relative">
          <h3 className="flex flex-col items-center text-4xl text-secondary font-bold mb-12">
            {`Our mission`} <span className="bg-primary h-1 w-20 block mt-4" />
          </h3>
          <div className="flex flex-col md:flex-row xl:px-32">
            <div className="md:w-1/3 sm:py-6 flex flex-col items-center md:px-6 lg:px-12">
              <h4 className="text-center font-semibold text-2xl text-secondary mb-2">
                {`Make the HC supply chain end-to-end`}
              </h4>
              <p className="text-center text-secondary-700 leading-relaxed">
                {`support all parties participating in the recruitment process`}
              </p>
            </div>
            <div className="md:w-1/3 sm:py-6 flex flex-col items-center md:px-6 lg:px-12">
              <h4 className="text-center font-semibold text-2xl text-secondary mb-2">
                {`Maximize HC efficiency`}
              </h4>
              <p className="text-center text-secondary-700 leading-relaxed">
                {`Helps employers to identify the right job seekers for their organization`}
              </p>
            </div>
            <div className="md:w-1/3 sm:py-6 flex flex-col items-center md:px-6 lg:px-12">
              <h4 className="text-center font-semibold text-2xl text-secondary mb-2">
                {`Help to find dream career paths`}
              </h4>
              <p className="text-center text-secondary-700 leading-relaxed">
                {`Helps job seekers find their dream job where they will be able to fulfill their potential to the most`}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-blue-100 mt-32 py-12" id="about-us">
        <div className="container mx-auto px-6">
          <div className="flex flex-col md:flex-row">
            <div className="md:w-1/2 md:pr-8 lg:pr-16">
              <img
                alt="doctor"
                className="-mt-24 md:mt-0 lg:-mt-24 mb-16 md:mb-0"
                src="/img/nakamoto.svg"
              />
            </div>
            <div className="md:w-1/2">
              <h3 className="flex flex-col text-4xl text-secondary font-bold mb-6">
                {`About us`} <span className="bg-primary h-1 w-20 block mt-4" />
              </h3>
              <p className="text-lg text-secondary-700 mb-4">
                {`We believe that it should never be your gender, age, race, religion, origin or economic status that define your ability to perform at the highest level in your chosen line of work. researchers around the world have proven time and again that organizations that build truly diverse and inclusive teams achieve up to 30% better financial results.`}
              </p>
              <p className="text-lg text-secondary-700">
                {`JOBIFY is trying to utilize AI and Blockchain technologies to accurately match and connect the right candidate to the right role in a non-biased and effortless way. Globally.`}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="relative" id="services">
        <img
          alt="circle"
          className="absolute top-0 right-0 mt-64 hidden md:block"
          src="/img/circle.svg"
        />
        <div className="container mx-auto px-6 py-32 relative">
          <h3 className="flex flex-col items-center text-4xl text-secondary font-bold">
            {"Services we offer "}
            <span className="bg-primary h-1 w-20 block mt-4" />
          </h3>
          <div className="flex flex-col md:flex-row items-center mb-24 md:mb-16 xl:mb-8 mt-16 md:mt-0 md:mt-16 lg:mt-0">
            <img alt="service" className="md:w-1/3" src="/img/service.svg" />
            <div className="md:ml-16 xl:ml-32">
              <h4 className="text-2xl md:text-3xl font-bold text-secondary-800 mb-4">
                {`Candidates`}
              </h4>
              <p className="text-secondary-700 text-lg mb-4">
                {`With a focus on non-biased assessment throughout the recruitment process through a carefully designed algorithm. We give every person around the world equal opportunity to search and secure their dream job. And what’s more - you can find your actual dream job based not just on your professional experience to date, but things that you are great at and love doing.
Because of Blockchain you gain full control over your Secure Identity and the professional references you share by determining permissions.`}
              </p>
            </div>
          </div>
          <div className="flex flex-col-reverse md:flex-row items-center mb-24 md:mb-16 xl:mb-8">
            <div className="md:mr-16 xl:mr-32">
              <h4 className="text-2xl md:text-3xl font-bold text-secondary-800 mb-4">
                {`Employers`}
              </h4>
              <p className="text-secondary-700 text-lg mb-4">
                {`Candidates are identified and presented to the hiring manager in minutes. Corporate profile assessment based on Augmented Intelligence and Machine Learning allows us to generate a comprehensive analysis of the company’s corporate culture, short, medium & long term objectives as well as public sentiment.
Our non-biased recruitment focussed matching algorithm recommends candidates who are best suited to each company and have the highest chance of success.
Now companies have instant access to CVs with accurate and verified details directly from the candidate.`}
              </p>
            </div>
            <img alt="cv" className="md:w-1/3" src="/img/cv.svg" />
          </div>
          <div className="flex flex-col md:flex-row items-center">
            <img alt="check-cv" className="md:w-1/3" src="/img/check-cv.svg" />
            <div className="md:ml-16 xl:ml-32">
              <h4 className="text-2xl md:text-3xl font-bold text-secondary-800 mb-4">
                {`Recruiters`}
              </h4>
              <p className="text-secondary-700 text-lg mb-4">
                {`We design tools that allow recruiters to advance into the digital era, provide special recruitment services that are otherwise very costly for companies, like CV review, background check, personality test, competency assessment, suggest the most suitable candidate and much more.`}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="relative" id="why-jobify">
        <div className="container mx-auto px-6 py-10 relative">
          <h3 className="flex flex-col items-center text-4xl text-secondary font-bold">
            {"Why JOBIFY?"}
            <span className="bg-primary h-1 w-20 block mt-4" />
          </h3>
          <div className="flex flex-col md:flex-row items-center mb-24 md:mb-16 xl:mb-8 mt-16 md:mt-0 md:mt-16 lg:mt-0">
            <p className="text-secondary-700 text-lg my-4">
              {`Say goodbye to silos and embrace a single sourcing hiring platform. JOBIFY's decentralized ecosystem creates a full HC lifecycle seamlessly uniting the advantages of a world-class ATS, social media based talent engagement application, labor market analytics based on crawled job posts from many different platforms, assessment & background check solutions.`}
            </p>
          </div>
          <div className="my-4">
            <Carousel autoPlay interval={3000}>
              <div>
                <img alt="demo" src="/img/slide1.png" />
              </div>
              <div>
                <img alt="demo" src="/img/slide2.png" />
              </div>
              <div>
                <img alt="demo" src="/img/slide3.png" />
              </div>
              <div>
                <img alt="demo" src="/img/slide4.png" />
              </div>
              <div>
                <img alt="demo" src="/img/slide5.png" />
              </div>
            </Carousel>
          </div>
        </div>
      </div>

      <div className="bg-primary" id="testimonials">
        <div className="mt-16">
          <div className="bg-primary pt-16 pb-16 md:pb-32 relative">
            <img
              alt="wave3"
              className="w-full absolute bottom-full h-16 lg:h-auto object-cover object-top"
              src="/img/wave3.svg"
            />
            <div className="container px-6 mx-auto">
              <div className="md:w-2/3 mx-auto relative">
                <img
                  alt="quote"
                  className="absolute top-0 left-0 sm:-ml-16 -mt-4"
                  src="/img/quote.svg"
                />
                <h3 className="text-white italic text-2xl text-center">
                  {`Our team at Jobify is always on the lookout for new technological and strategical solutions to make recruitment processes more secure and efficient.`}
                </h3>
                <h3 className="text-white italic text-2xl text-center">
                  {`As a team of young professionals, we have experienced the ups and downs of finding the ideal position of employment on the one hand and recruiting new talents for our company on the other hand. Through the years we have carefully analyzed many aspects of job finding and recruitment, identified the advantages and shortcomings of current common practices and ATSs, and envisioned the necessary transformations. We are now committed to making our vision a reality by helping job-seekers fulfill their aspirations and enabling recruiters to drive their company forward by finding the most fitting and ambitious employees in a modern and cost-effective way.`}
                  <strong className="block not-italic mt-6 text-white">
                    {`JOBIFY`}
                  </strong>
                </h3>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-center -mt-8 md:-mt-16 lg:-mt-24 relative">
            <a href="/">
              <img
                alt="avatar"
                className="w-12 h-12 md:w-24 md:h-24 lg:w-32 lg:h-32 mx-2 lg:mx-4 object-cover rounded-full border-2 md:border-4 border-white"
                src="/img/avatar3.png"
              />
            </a>
            <a href="/">
              <img
                alt="avatar"
                className="w-12 h-12 md:w-24 md:h-24 lg:w-32 lg:h-32 mx-2 lg:mx-4 object-cover rounded-full border-2 md:border-4 border-white"
                src="/img/avatar2.png"
              />
            </a>
            <a href="/">
              <img
                alt="avatar"
                className="w-16 h-16 md:w-32 md:h-32 lg:w-48 lg:h-48 mx-2 lg:mx-4 object-cover rounded-full border-2 md:border-4 border-white"
                src="/img/avatar1.png"
              />
            </a>
            <a href="/">
              <img
                alt="avatar"
                className="w-12 h-12 md:w-24 md:h-24 lg:w-32 lg:h-32 mx-2 lg:mx-4 object-cover rounded-full border-2 md:border-4 border-white"
                src="/img/avatar9.png"
              />
            </a>
            <a href="/">
              <img
                alt="avatar"
                className="w-12 h-12 md:w-24 md:h-24 lg:w-32 lg:h-32 mx-2 lg:mx-4 object-cover rounded-full border-2 md:border-4 border-white"
                src="/img/avatar8.png"
              />
            </a>
          </div>

          <div className="flex items-center justify-center mt-8 md:-mt-16 lg:mt-12 relative">
            <a href="/">
              <img
                alt="avatar"
                className="w-12 h-12 md:w-24 md:h-24 lg:w-32 lg:h-32 mx-2 lg:mx-4 object-cover rounded-full border-2 md:border-4 border-white"
                src="/img/avatar5.png"
              />
            </a>
            <a href="/">
              <img
                alt="avatar"
                className="w-12 h-12 md:w-24 md:h-24 lg:w-32 lg:h-32 mx-2 lg:mx-4 object-cover rounded-full border-2 md:border-4 border-white"
                src="/img/avatar6.png"
              />
            </a>
            <a href="/">
              <img
                alt="avatar"
                className="w-12 h-12 md:w-24 md:h-24 lg:w-32 lg:h-32 mx-2 lg:mx-4 object-cover rounded-full border-2 md:border-4 border-white"
                src="/img/avatar4.png"
              />
            </a>
            <a href="/">
              <img
                alt="avatar"
                className="w-12 h-12 md:w-24 md:h-24 lg:w-32 lg:h-32 mx-2 lg:mx-4 object-cover rounded-full border-2 md:border-4 border-white"
                src="/img/avatar7.png"
              />
            </a>
          </div>
        </div>
        <div className="container mx-auto px-6 py-24" id="contact">
          {/* <h3 className="flex flex-col items-center text-4xl text-white font-bold mb-12">
            {"I need more info! "}
            <span className="bg-primary h-1 w-20 block mt-4" />
          </h3>
          <div className="flex justify-center mb-4">
            <a href="/">
              <img
                alt="facebook"
                className="h-10 px-10"
                src="/img/facebook.svg"
              />
            </a>
            <a href="/">
              <img
                alt="instagram"
                className="h-10 px-10"
                src="/img/instagram.svg"
              />
            </a>
            <a href="/">
              <img
                alt="linkedin"
                className="h-10 px-10"
                src="/img/linkedin.svg"
              />
            </a> 
          </div>
          */}
        </div>
      </div>
      <footer className="bg-blue-100">
        <div className="container mx-auto px-6 py-12 text-secondary-500 text-center border-t border-gray-300">
          <p>
            {`Copyright ${year} JOBIFY. All rights reserved. | Powered by `}
            <a
              className="text-secondary-900 font-bold underline"
              href="https://hike.studio/"
            >
              {`HIKE STUDIO`}
            </a>
          </p>
        </div>
      </footer>
    </div>
  );
}
